@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('assets/styles/tailwind.css');


body > iframe[style*='2147483647']{
    display: none;
  }


  .Toastify {
    > div {
      min-width: 340px;
      width: fit-content !important;
      > div {
        min-width: 340px;
        padding: 0px 16px;
        color: black;
        width: fit-content;
      }
    }
  }
  

  .hs-form-field > label >span{
    color: black;
  }