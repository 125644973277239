.public_view_color{
  background-color: #ffff;
}


.loader_screen{
  height: 500px;

  .loader-spinner {
    font-size: 10px;
    width: 1em !important;
    height: 1em !important;
    display: block;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: loadSpin 1.1s infinite ease;
    transform: translateZ(0);
  
    @media (min-width: 1024px) {
      font-size: 20px;
    }
  }
  @keyframes loadSpin {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #000000,
        1.8em -1.8em 0 0em rgba(40, 40, 40, 0.2),
        2.5em 0em 0 0em rgba(40, 40, 40, 0.2),
        1.75em 1.75em 0 0em rgba(40, 40, 40, 0.2),
        0em 2.5em 0 0em rgba(40, 40, 40, 0.2),
        -1.8em 1.8em 0 0em rgba(40, 40, 40, 0.2),
        -2.6em 0em 0 0em rgba(40, 40, 40, 0.5),
        -1.8em -1.8em 0 0em rgba(40, 40, 40, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(40, 40, 40, 0.7),
        1.8em -1.8em 0 0em #000000, 2.5em 0em 0 0em rgb(40, 40, 40, 0.2),
        1.75em 1.75em 0 0em rgba(40, 40, 40, 0.2),
        0em 2.5em 0 0em rgba(40, 40, 40, 0.2),
        -1.8em 1.8em 0 0em rgba(40, 40, 40, 0.2),
        -2.6em 0em 0 0em rgba(40, 40, 40, 0.2),
        -1.8em -1.8em 0 0em rgba(40, 40, 40, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgb(40, 40, 40, 0.5),
        1.8em -1.8em 0 0em rgba(40, 40, 40, 0.7), 2.5em 0em 0 0em #000000,
        1.75em 1.75em 0 0em rgba(40, 40, 40, 0.2),
        0em 2.5em 0 0em rgba(40, 40, 40, 0.2),
        -1.8em 1.8em 0 0em rgba(40, 40, 40, 0.2),
        -2.6em 0em 0 0em rgba(40, 40, 40, 0.2),
        -1.8em -1.8em 0 0em rgba(40, 40, 40, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(40, 40, 40, 0.2),
        1.8em -1.8em 0 0em rgba(40, 40, 40, 0.5),
        2.5em 0em 0 0em rgba(40, 40, 40, 0.7), 1.75em 1.75em 0 0em #000000,
        0em 2.5em 0 0em rgba(40, 40, 40, 0.2),
        -1.8em 1.8em 0 0em rgba(40, 40, 40, 0.2),
        -2.6em 0em 0 0em rgba(40, 40, 40, 0.2),
        -1.8em -1.8em 0 0em rgba(40, 40, 40, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(40, 40, 40, 0.2),
        1.8em -1.8em 0 0em rgba(40, 40, 40, 0.2),
        2.5em 0em 0 0em rgba(40, 40, 40, 0.5),
        1.75em 1.75em 0 0em rgba(40, 40, 40, 0.7), 0em 2.5em 0 0em #000000,
        -1.8em 1.8em 0 0em rgba(40, 40, 40, 0.2),
        -2.6em 0em 0 0em rgba(40, 40, 40, 0.2),
        -1.8em -1.8em 0 0em rgba(40, 40, 40, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(40, 40, 40, 0.2),
        1.8em -1.8em 0 0em rgba(40, 40, 40, 0.2),
        2.5em 0em 0 0em rgba(40, 40, 40, 0.2),
        1.75em 1.75em 0 0em rgba(40, 40, 40, 0.5),
        0em 2.5em 0 0em rgba(40, 40, 40, 0.7), -1.8em 1.8em 0 0em #000000,
        -2.6em 0em 0 0em rgba(40, 40, 40, 0.2),
        -1.8em -1.8em 0 0em rgba(40, 40, 40, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(40, 40, 40, 0.2),
        1.8em -1.8em 0 0em rgba(40, 40, 40, 0.2),
        2.5em 0em 0 0em rgba(40, 40, 40, 0.2),
        1.75em 1.75em 0 0em rgba(40, 40, 40, 0.2),
        0em 2.5em 0 0em rgba(40, 40, 40, 0.5),
        -1.8em 1.8em 0 0em rgba(40, 40, 40, 0.7), -2.6em 0em 0 0em #000000,
        -1.8em -1.8em 0 0em rgba(40, 40, 40, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(40, 40, 40, 0.2),
        1.8em -1.8em 0 0em rgba(40, 40, 40, 0.2),
        2.5em 0em 0 0em rgba(40, 40, 40, 0.2),
        1.75em 1.75em 0 0em rgba(40, 40, 40, 0.2),
        0em 2.5em 0 0em rgba(40, 40, 40, 0.2),
        -1.8em 1.8em 0 0em rgba(40, 40, 40, 0.5),
        -2.6em 0em 0 0em rgba(40, 40, 40, 0.7), -1.8em -1.8em 0 0em #000000;
    }
  }
}


// .sG4Xue.gjGrxc.lHMCbd{
//   display: none;
// }

// .MjjYud{
//   >div{
// padding: 10px 30px;
//     background-color:#ffff ;
//   }
//   padding: 10px 10px;
// }


.postClickHtml{
  padding: 20px 40px;
  .dodTBe{
    display: none;
  }
  .CvDJxb{
    display: none;
  }
.Kgs6dd{
  display: none;
}
.sG4Xue.gjGrxc.YzCcne{
  display: none;
}
.s6JM6d{
  width: 50%;
}
.TQc1id.rhstc4{
  width: 50%;
}
.UxeQfc{
  width: 100% !important;
.LT6XE{
  width: 50%;
}
.kLMmLc{
  width: 50%;
  .BOThhc{
    width: 100% !important;
  }
}
}
.n47m8b.cCsnA{
  display: none !important;
}
}

.postClickHtml{
max-height: 500px;
height: 100%;
overflow-y: auto;
/* width */
&::-webkit-scrollbar {
  width: 8px;
}

/* Track */
&::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px black; 
}
 
/* Handle */
&::-webkit-scrollbar-thumb {
  background: #0284C7; 
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  background: #0284C7; 
}
.WRhYSc.VDgVie{
  display: none !important;
}
.aAbqZ{
  width: 90%;
}
.xhjkHe{
  flex-direction: column !important;
width: 90%;
  .TrmO7{
    width: 90%;
  }
  .sKb6pb{
    display: none !important;
  }
}
.xyTTy{
  width: 90%;
}
.wgbRNb.VdehBf.OZ5bRd.Yze26d.tHT0l{
  display: none;
}

.EDblX.GpHuwc{
width: 100%;
}
.yXu61c{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.z1asCe.ToMkpe{
  display: none;
}
}
