.icon-class {
  right: 1rem !important;
  top: 36% !important;
}

.rotate-180 {
  rotate: 180deg;
}

.title {
  text-align: start;
}

.custom-select-container:not(.haserror):hover {
  box-shadow: 1px 0px 24px 2px rgba(0, 0, 0, 0.23);
  -webkit-box-shadow: 1px 0px 24px 2px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 1px 0px 24px 2px rgba(0, 0, 0, 0.23);
}

.custom-select-container {
  display: inline-block;
  text-align: center;
  position: relative;
  width: 100%;

  .haserror {
    outline: 1px solid;
    outline-color: #ff0000;
    color: #ff0000;
    background-color: white;
    border-radius: 4px;
    padding: 11px 20px;
    text-align: start;
    font-size: 14px;
    line-height: 22px;
  }
  &:hover {
    box-shadow: 1px -1px 15px -4px rgba(255, 0, 0, 0.7);
    -webkit-box-shadow: 1px -1px 15px -4px rgba(255, 0, 0, 0.7);
    -moz-box-shadow: 1px -1px 15px -4px rgba(255, 0, 0, 0.7);
  }
  .selected-text {
    background-color: white;
    border-radius: 4px;
    padding: 11px 20px;
    text-align: start;
    font-size: 14px;
    line-height: 22px;
  }
  .error_child_class {
    color: #ff0000;
  }

  .selected-text-default {
    background-color: white;
    border-radius: 4px;
    padding: 11px 20px;
    text-align: start;
    font-size: 14px;
    line-height: 22px;
  }
  ul {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .select-options {
    position: absolute;
    width: 100%;
    z-index: 200;
    max-height: 150px;
    overflow: auto;
    top: 47px;
    box-shadow: 0px 9px 14px 3px rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0px 9px 14px 3px rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0px 9px 14px 3px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }

  li {
    list-style-type: none;
    padding: 6px 20px;
    background: white;
    cursor: pointer;
    text-align: start;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  li:hover {
    background-color: rgb(224, 224, 224);
  }

  .icon_abolute_class_select {
    top: 30%;
    right: 35px;
    > svg {
      width: 20px;
      height: 20px;
    }
  }
}
