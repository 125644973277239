.max-w-form{
    max-width: 720px;
    z-index: 999;
}

.transition-width{
    transition: width 0.3s;
}

.w-0{
    width: 0px;
}

.parent-bg-color{
    background-color: rgba(0, 0, 0, 0.06);
}

.close_button_color{
    background-color: black;
    width: 30px;
    height: 30px;
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;
    >svg{
        fill: #FADD23;
    }
}