.dashbaord-charts-container {
  display: grid;
  gap: 40px;

  padding: 0px 12px;
  padding-top: 24px !important;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0px 12px;
    gap: 30px;
  }

  @media (min-width: 1512px) {
    gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    padding: 0px 70px 0px 24px;
  }
}

.container_page_visit_stats {
  padding: 0px 12px;

  @media (min-width: 1024px) {
    padding: 0px 24px;
  }
}

.dashaboard_class_top {
  max-width: 1640px;
  margin: 0 auto;
  padding-bottom: 30px;

  @media (min-width: 1024px) {
    padding-bottom: 100px;
  }
}

.dashbaord-charts-container {
  .subhaeding_charts {
    font-weight: 600;
    text-wrap: wrap;
    color: #334155;
    font-size: 16px;

    @media (min-width: 1024px) {
      font-size: 20px;
    }
  }
}

.apexcharts-legend,
.apexcharts-align-center.apx-legend-position-bottom {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6px;

  @media (min-width: 786px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.container_report_cta {
  padding: 0px 12px;

  @media (min-width: 1024px) {
    padding: 0px 24px;
  }
}

.top_header {
  background-color: rgb(199, 199, 199);
  color: black;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

.rounded-tooltip {
  border-radius: 4px;
}

.bottom_text {
  >p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
}

.rounded-icon-red {
  background-color: #f79f9f;
  border: 1px solid #ff4040;
  border-radius: 9999px;
  width: 10px;
  height: 10px;
}

.rounded-icon-blue {
  border-radius: 9999px;
  width: 10px;
  height: 10px;
  background-color: #80afff;
  border: 1px solid rgba(32, 120, 255, 0.4);
}

.button_class_message {
  padding: 15px 15px;
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 100;
  border-radius: 9999px;
  background-color: #0284C7;

  >svg {
    fill: white;
  }

  box-shadow: 1px 1px 28px 3px rgba(0, 0, 0, 0.57);
  -webkit-box-shadow: 1px 1px 28px 3px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 1px 1px 28px 3px rgba(0, 0, 0, 0.57);

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: white;

    >svg {
      fill: black;
    }
  }
}

.container_content_class {
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.448);

}

.class_child_container {
  max-width: 620px;
  width: 100%;
  background-color: white;
  max-height: 100vh;
  overflow: auto;
}


.close_button {
  padding: 5px 5px;
  z-index: 100;
  border-radius: 9999px;
  background-color: #0284C7;

  >svg {
    fill: white;
  }

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #1699da;

    >svg {
      fill: white;
    }
  }
}

.top_bar {
  background-color: #dde2e7;
  padding: 10px 20px;
}

.content_container {
  padding: 20px 20px;
}


.CardContainer {
  width: fit-content;
}

.user_icon {
  padding: 5px 5px;
  z-index: 100;
  width: fit-content;
  border-radius: 9999px;
  background-color: #0284C7;

  >svg {
    fill: white;
  }
}


.text_justify_class {
  text-align: justify;
}

.top_bar_clas_public_color {
  background-color: #F8FAFC;
}

.top_bar_class_public {
  max-width: 1640px;
  margin: 0 auto;
  padding: 20px 0px 20px 50px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;

}

.pagination-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  background-color: #F8FAFC;
}

.keyword_class {
  max-width: 200px;
  text-wrap: wrap;
}



.hover_class_tr {
  opacity: 1;

  &:hover {
    box-shadow: -3px 8px 17px 0px rgba(0, 0, 0, 0.32);
    -webkit-box-shadow: -3px 8px 17px 0px rgba(0, 0, 0, 0.32);
    -moz-box-shadow: -3px 8px 17px 0px rgba(0, 0, 0, 0.32);
    cursor: pointer;
    opacity: 0.8;
  }
}

.ftzeFU {
  white-space: pre-wrap !important;
}



.form-overlay {
  background-color: #00000083;
}

.z-parent-div {
  z-index: 9999;
}


.z-index-root-class {
  z-index: 1;
}


.button_retry {
  border-radius: 10px;
  padding: 8px 34px;
  width: 144.31px;
  color: #f66868;
  outline: 1px solid #f66868;

  >svg {
    fill: #f66868;
    animation: spin 3s infinite linear;
  }

  &:hover {
    background-color: #f66868;
    color: white;
    outline: 0px;

    >svg {
      fill: white;
      animation-play-state: paused;
    }
  }

  &:active {
    outline: 1px solid #f66868;
  }

  &:focus {
    outline: 1px solid #f66868;
  }
}

.button_retry_default {
  border-radius: 10px;
  padding: 8px 34px;
  width: 144.31px;
  color: #f66868;
  outline: 1px solid #f66868;

  >svg {
    fill: #f66868;
  }

  &:hover {
    background-color: #f66868;
    color: white;
    outline: 0px;

    >svg {
      fill: white;
    }
  }

  &:active {
    color: #f66868;
    outline: 1px solid #f66868;

    >svg {
      fill: #f66868;
    }
  }

  &:focus {
    color: #f66868;
    outline: 1px solid #f66868;

    >svg {
      fill: #f66868;
    }
  }
}

.retry_container {
  padding: 0px 10px;

  @media (min-width:1024px) {
    padding: 0px 70px;

  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.FEoSJf.inQIab.C9KyDd{
  display: none;
}