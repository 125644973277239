.hover_class_tr {
  opacity: 1;
  &:hover {
    box-shadow: -3px 8px 17px 0px rgba(0, 0, 0, 0.32);
    -webkit-box-shadow: -3px 8px 17px 0px rgba(0, 0, 0, 0.32);
    -moz-box-shadow: -3px 8px 17px 0px rgba(0, 0, 0, 0.32);
    cursor: pointer;
    opacity: 0.8;
  }
}
.last_class_row {
  padding-bottom: 32px;
}

.top_class_row {
  padding-top: 32px;
}

.no_rows_found_table {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.parent_no_row_found {
  @media (min-width: 1024px) {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  > div {
    @media (min-width: 1024px) {
      width: 100%;
    }
  }
}
.button_add_project {
  opacity: 1;
  border-radius: 10px;
  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 0.7;
  }
  &:focus {
    opacity: 0.7;
  }
}
.skeleton_parent_scroll{
  overflow:auto;
}