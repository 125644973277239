.padd-40{
    padding-left: 40px !important;
}

.admin_desktop_navbar{
    width: 100%;
 left: 0px;
 right: auto;
    @media (min-width:766px) {
        width: calc(100% - 256px);
        right: 0px;
        left: auto;
    }

    display: none;


    @media (min-width:770px) {
        display: flex;

    }
}