.btn-file {
  position: relative;
  overflow: hidden;
  &:hover {
    box-shadow: 1px 0px 24px 2px rgba(0, 0, 0, 0.23);
    -webkit-box-shadow: 1px 0px 24px 2px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 1px 0px 24px 2px rgba(0, 0, 0, 0.23);
  }
}

.normal-input {
  &:hover {
    box-shadow: 1px 0px 24px 2px rgba(0, 0, 0, 0.23);
    -webkit-box-shadow: 1px 0px 24px 2px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 1px 0px 24px 2px rgba(0, 0, 0, 0.23);
  }
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.button-class-hover {
  &:hover {
    opacity: 0.7;
  }
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
}

.project_dropdown {
  top: 51px;
  left: 25px;
}

.dropdown_button_hover {
  &:hover {
    background-color: #0284c7;
    color: white;
    > svg {
      path {
        fill: white;
      }
    }
  }
}
.file_input_error {
  outline: 1px solid;
  outline-color: #ff0000;
  color: #ff0000;
}
.svg_class_style_cloud_warning {
  fill: #ff0000;
}

.icon_abolute_class {
  top: 52%;
  right: 15px;
  svg {
    width: 20px;
    height: 20px;
  }
}
.icon_abolute_class_file {
  top: 35%;
  right: 16px;
  svg {
    width: 20px;
    height: 20px;
  }
}

.primary_error_class {
  outline: 1px solid;
  outline-color: #ff0000;
  color: #ff0000;
  font-size: 14px;
  line-height: 24px;
  font-family: 400;
  &::placeholder {
    color: #ff0000;
    opacity: 0.5;
  }
  &:focus {
    box-shadow: none !important;
    outline-color: #ff0000;
  }
}

.button-loader-icon {
  width: fit-content;
  height: fit-content;
  animation: processing 2.2s linear infinite;
}

@keyframes processing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button_loading_style {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: #949494;
  cursor: not-allowed;
  opacity: 1;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
}
