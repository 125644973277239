.logout_button {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: rgb(0 0 0 / 8%);
  }
  > img {
    width: 20px;
    height: 20px;
  }
}

.dropdown_class {
  top: 66px;
  right: 50px;
}
