.Toastify {
  > div {
    min-width: 340px;
    width: fit-content !important;
    > div {
      min-width: 340px;
      padding: 0px 16px;
      color: black;
      width: fit-content;
      background-color: #e2e8f0;
    }
  }
}
