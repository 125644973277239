.guageChartContainer {
  > svg {
    position: relative;
    g {
      .doughnut {
        position: absolute;
        top: 0px;
        left: 0px;
      }
    }
  }
}

.value-text {
  text {
    fill: #000000 !important;
    text-shadow: none !important;
    font-weight: 500;
    font-size: 36px !important;
  }
}

.rounded-full {
  border-radius: 9999px;
}
