.header_stats_container {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(1, 1fr);
  @media (min-width: 1024px) {
    gap: 40px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1280px) {
    gap: 40px;
    grid-template-columns: repeat(4, 1fr);
  }
}

.root_class_header_stats {
  padding-bottom: 40px;
}

.restricted_container {
  max-width: 1640px;
  margin: 0 auto;
  padding: 20px 12px;

  @media (min-width: 786px) {
    padding: 110px 0px 40px 0px;
  }
}

.card_stat_left {
  width: 70%;
}

.card_stat_right {
  .rounded-full {
    border-radius: 9999px;
  }
}

.button_class_style{
  border-radius: 10px;
  padding: 8px 34px;
  width: 144.31px;

  &:hover{
    background-color: #F1F5F9;
  }
  &:active{
    outline: none;
  }
  &:focus{
    outline: none;
  }
}


.button-loader-icon {
  width: fit-content;
  height: fit-content;
  animation: processing 2.2s linear infinite;
}

@keyframes processing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button_loading_style_notes {
  display: flex;
  gap: 2px;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
  flex-direction: row;
  color:white;
  background-color: #c5c3c3fa;
  cursor: not-allowed;
  opacity: 1;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
}
.restricted_container_public{
  max-width: 1640px;
  margin: 0 auto;
  padding: 20px 12px;

  @media (min-width: 786px) {
    padding: 40px 0px 40px 0px;
  }

}


.restricted_msg_container {
  max-width: 1640px;
  margin: 0 auto;
  padding:  20px 20px 0px 20px;
  max-height: 350px;
  overflow: auto;
 

  &::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
   
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: white; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgb(199, 198, 198); 
  }

  @media (min-width: 786px) {
    padding: 40px 40px 0px 40px;
  }
}

.inner_container_msg{
  padding: 16px 16px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  @media (min-width: 1024px) {
    padding: 24px 24px;
  }
}

.button_delete_note{
position: absolute;
display: none;
top: -10px;
right: -25px;
  background-color: white;
  border-radius: 9999px;
  padding:10px 10px;
  width: fit-content;
  border: 1px solid #f92e3f;
  >svg{
    fill: #f92e3f;
  }
}

.restricted_msg_container{
  .inner_container_msg{
    .mesage_container{
      .button_delete_note{
        display: none;
      }
      &:hover{
      outline: 1px solid #f92e3f;
      border-radius:10px ;
      cursor: pointer;
      .button_delete_note{
        display: block;
      }
      }  
    }

  }
}

.no_hover{
  cursor: default;
  max-width: 1640px;
  margin: 0 auto;
  padding:  20px 20px 0px 20px;
  max-height: 350px;
  overflow: auto;
 

  &::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
   
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: white; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgb(199, 198, 198); 
  }

  @media (min-width: 786px) {
    padding: 40px 40px 0px 40px;
  }
}

.inner_container_msg_public{
  padding: 16px 16px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  cursor: default;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  @media (min-width: 1024px) {
    padding: 24px 24px;
  }
}

.share_container{
  margin-top: 0px;
  padding-top: 16px;
  padding-right: 16px;
  justify-content: flex-start;


  @media (min-width:330px) {
    margin-top: -60px;
    padding-top: 16px;
    padding-right: 0px;
    justify-content: flex-end;
      
  }


  @media (min-width:760px) {
    margin-top: -60px;
    padding-top: 16px;
    padding-right: 40px;
    justify-content: flex-end;

  }
}