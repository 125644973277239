.hover_class_anchor {
  &:hover {
    text-decoration: underline;
    font-weight: 600;
    color: #2660f2;
  }
}

.content_wrapper {
  padding: 0 16px;
  @media (min-width: 1024px) {
    padding: 0 160px;
  }
  > .intro_content {
    max-width: 1024px;
    margin: 0 auto;
    padding: 20px 0px;
    > p {
      span {
        font-style: italic;
        color: #2660f2;
      }
    }
  }
}

.heading_color {
  color: #fadd23;
}

.image_content_wrapper {
  max-width: 1024px;
  margin: 0 auto;
  padding: 16px;
  display: flex;
  gap: 16px;
  align-items: center;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
    padding: 20px 0px;
  }
}
.w-left {
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 1024px) {
    align-items: start;
    img {
      width: 150px;
      height: 150px;
    }
  }
  @media (min-width: 1024px) {
    width: 20%;
  }
}
.w-right {
  width: 100%;
  @media (min-width: 1024px) {
    width: 80%;
  }
}

.unordered_list_style {
  list-style: disc;
  padding-left: 20px;
  > li {
    padding-bottom: 6px;
    span {
      color: #2660f2;
      font-size: 16px;
      font-weight: 700;
      padding-right: 8px;
      @media (min-width: 1024px) {
        font-size: 22px;
      }
    }
  }
}

.background_image_container {
  background-image: url("../img/background.png");
  background-position: 115px -70px;
  margin-top: 20px;
  @media (min-width: 1024px) {
    margin-top: 40px;
  }
}
.background-color-black {
  background-color: rgba(0, 0, 0, 0.6);
}

.content_wrapper_background_image {
  padding-top: 20px;
  > p {
    max-width: 1024px;
    margin: 0 auto;
    padding: 0px 16px;
    color: rgba(255, 255, 255, 0.809);
    @media (min-width: 1024px) {
      padding: 0px 0px;
    }
    a {
      text-decoration: underline;
      color: #fadd23;
      padding: 0 10px;
      &:hover {
        color: #ffdd00c0;
      }
    }
  }
  .large_content {
    a {
      color: white;
      &:hover {
        color: #fadd23;
      }
    }
  }
}

.blue_color_continer {
  background-color: #2058e5b1;
}


.button_style_default{
  color: black;
  font-weight: 500;
  &:hover{
    font-weight: 500;
    color: #2660f2;
  }
  &:active{
    outline: 0px;
    box-shadow: none;
  }
  &:focus{
    outline: 0px;
    box-shadow: none;
  }
}
.button_style_default_dark{
  color: white;
  font-weight: 500;
  &:hover{
    font-weight: 500;
    color: #fadd23;
    outline: 0px;
    box-shadow: none;
  }
  &:active{
    outline: 0px;
    box-shadow: none;
  }
  &:focus{
    outline: 0px;
    box-shadow: none;
  }
}

.contact_us_button{
  color: #1759FF !important;;
  font-weight: 600;
  background-color: #fadd23;
  padding: 5px 30px;
  border-radius: 20px;
  opacity: 1;
   
  &:hover{
    opacity: 0.9;
    outline: 0px;
    box-shadow: none;
  }
  &:active{
    outline: 0px;
    box-shadow: none;
  }
  &:focus{
    outline: 0px;
    box-shadow: none;
  }
}

.contact_us_button_top{
  color: #1759FF !important;
  font-weight: 600;
  background-color: #fadd23 !important;
  padding: 5px 30px;
  border-radius: 20px;
  opacity: 1;
   
  &:hover{
    opacity: 0.9;
    outline: 0px;
    box-shadow: none;
  }
  &:active{
    outline: 0px;
    box-shadow: none;
  }
  &:focus{
    outline: 0px;
    box-shadow: none;
  }
}

.pad-right-200{
  padding-right: 8px;
 }

 .border_radius_contact{
border-radius: 20px;
 }