*,
::before,
::after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
}

html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

body {
  margin: 0;
}

body {
  font-family: system-ui, -apple-system,
    /* Firefox supports this but not yet `system-ui` */ "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

hr {
  height: 0; /* 1 */
  color: inherit; /* 2 */
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, "Liberation Mono", Menlo,
    monospace; /* 1 */
  font-size: 1em; /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/*
Tabular data
============
*/

/**
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
*/

table {
  text-indent: 0; /* 1 */
  border-color: inherit; /* 2 */
}

/*
Forms
=====
*/

/**
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
*/

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
Remove the inheritance of text transform in Edge and Firefox.
1. Remove the inheritance of text transform in Firefox.
*/

button,
select {
  /* 1 */
  text-transform: none;
}

/**
Correct the inability to style clickable types in iOS and Safari.
*/

button,
[type="button"] {
  -webkit-appearance: button;
}

/**
Remove the inner border and padding in Firefox.
*/

/**
Restore the focus styles unset by the previous rule.
*/

/**
Remove the additional ':invalid' styles in Firefox.
See: https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737
*/

/**
Remove the padding so developers are not caught out when they zero out 'fieldset' elements in all browsers.
*/

legend {
  padding: 0;
}

/**
Add the correct vertical alignment in Chrome and Firefox.
*/

progress {
  vertical-align: baseline;
}

/**
Correct the cursor style of increment and decrement buttons in Safari.
*/

/**
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
Remove the inner padding in Chrome and Safari on macOS.
*/

/**
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to 'inherit' in Safari.
*/

/*
Interactive
===========
*/

/*
Add the correct display in Chrome and Safari.
*/

summary {
  display: list-item;
}

/**
 * Manually forked from SUIT CSS Base: https://github.com/suitcss/base
 * A thin layer on top of normalize.css that provides a starting point more
 * suitable for web applications.
 */

/**
 * Removes the default spacing and border for appropriate elements.
 */

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

button {
  background-color: transparent;
  background-image: none;
}

/**
 * Work around a Firefox/IE bug where the transparent `button` background
 * results in a loss of the default `button` focus styles.
 */

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

fieldset {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/**
 * Tailwind custom reset styles
 */

/**
 * 1. Use the user's configured `sans` font-family (with Tailwind's default
 *    sans-serif font stack as a fallback) as a sane default.
 * 2. Use Tailwind's default "normal" line-height so the user isn't forced
 *    to override it to ensure consistency even when using the default theme.
 */

html {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; /* 1 */
  line-height: 1.5; /* 2 */
}

/**
 * Inherit font-family and line-height from `html` so users can set them as
 * a class directly on the `html` element.
 */

body {
  font-family: inherit;
  line-height: inherit;
}

/**
 * 1. Prevent padding and border from affecting element width.
 *
 *    We used to set this in the html element and inherit from
 *    the parent element for everything else. This caused issues
 *    in shadow-dom-enhanced elements like <details> where the content
 *    is wrapped by a div with box-sizing set to `content-box`.
 *
 *    https://github.com/mozdevs/cssremedy/issues/4
 *
 *
 * 2. Allow adding a border to an element by just adding a border-width.
 *
 *    By default, the way the browser specifies that an element should have no
 *    border is by setting it's border-style to `none` in the user-agent
 *    stylesheet.
 *
 *    In order to easily add borders to elements by just setting the `border-width`
 *    property, we change the default border-style for all elements to `solid`, and
 *    use border-width to hide them instead. This way our `border` utilities only
 *    need to set the `border-width` property instead of the entire `border`
 *    shorthand, making our border utilities much more straightforward to compose.
 *
 *    https://github.com/tailwindcss/tailwindcss/pull/116
 */

*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: #e4e4e7; /* 2 */
}

/*
 * Ensure horizontal rules are visible by default
 */

hr {
  border-top-width: 1px;
}

/**
 * Undo the `border-style: none` reset that Normalize applies to images so that
 * our `border-{width}` utilities have the expected effect.
 *
 * The Normalize reset is unnecessary for us since we default the border-width
 * to 0 on all elements.
 *
 * https://github.com/tailwindcss/tailwindcss/issues/362
 */

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #a1a1aa;
}

button,
[role="button"] {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[33px\] {
  font-size: 33px;
}

.text-\[36px\] {
  font-size: 36px;
}

.text-\[40px\] {
  font-size: 40px;
}

.text-\[54px\] {
  font-size: 54px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.lg\:min-w-\[350px\] {
  min-width: 350px;
}

.lg\:min-w-\[615px\] {
  min-width: 615px;
}

.lg\:max-w-\[1084px\] {
  max-width: 1084px;
}

.lg\:max-w-\[1200px\] {
  max-width: 1200px;
}

.lg\:max-w-\[120px\] {
  max-width: 120px;
}

.lg\:max-w-\[1512px\] {
  max-width: 1512px;
}

.lg\:max-w-\[280px\] {
  max-width: 280px;
}
.max-w-\[260px\] {
  max-width: 260px;
}

.lg\:max-w-\[332px\] {
  max-width: 332px;
}

.lg\:max-w-\[340px\] {
  max-width: 340px;
}

.lg\:max-w-\[343px\] {
  max-width: 343px;
}

.lg\:max-w-\[376px\] {
  max-width: 376px;
}

.lg\:max-w-\[381px\] {
  max-width: 381px;
}

.lg\:max-w-\[391px\] {
  max-width: 391px;
}

.lg\:max-w-\[393px\] {
  max-width: 393px;
}

.lg\:max-w-\[400px\] {
  max-width: 400px;
}

.lg\:max-w-\[500px\] {
  max-width: 500px;
}

.lg\:max-w-\[588px\] {
  max-width: 588px;
}

.lg\:max-w-\[720px\] {
  max-width: 720px;
}

.lg\:max-w-\[800px\] {
  max-width: 800px;
}

.lg\:px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.font-semibold {
  font-weight: 600;
}

/**
 * Reset links to optimize for opt-in styling instead of
 * opt-out.
 */

a {
  color: inherit;
  text-decoration: inherit;
}

/**
 * Reset form element properties that are easy to forget to
 * style explicitly so you don't inadvertently introduce
 * styles that deviate from your design system. These styles
 * supplement a partial reset that is already applied by
 * normalize.css.
 */

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

/**
 * Use the configured 'mono' font family for elements that
 * are expected to be rendered with a monospace font, falling
 * back to the system monospace stack if there is no configured
 * 'mono' font family.
 */

pre,
code,
kbd,
samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

/**
 * Make replaced elements `display: block` by default as that's
 * the behavior you want almost all of the time. Inspired by
 * CSS Remedy, with `svg` added as well.
 *
 * https://github.com/mozdevs/cssremedy/issues/14
 */

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

/**
 * Constrain images and videos to the parent width and preserve
 * their instrinsic aspect ratio.
 *
 * https://github.com/mozdevs/cssremedy/issues/14
 */

img,
video {
  max-width: 100%;
  height: auto;
}

[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="month"],
[type="search"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #71717a;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  border-color: #2563eb;
}

input::placeholder,
textarea::placeholder {
  color: #71717a;
  opacity: 1;
}

select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%2371717a' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  padding-right: 0.75rem;
  -webkit-print-color-adjust: unset;
  color-adjust: unset;
}

[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #71717a;
  border-width: 1px;
}

[type="checkbox"] {
  border-radius: 0px;
}

[type="checkbox"]:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

[type="checkbox"]:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover,
[type="checkbox"]:checked:focus {
  border-color: transparent;
  background-color: currentColor;
}

[type="checkbox"]:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

[type="checkbox"]:indeterminate:hover,
[type="checkbox"]:indeterminate:focus {
  border-color: transparent;
  background-color: currentColor;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-size: unset;
  line-height: inherit;
}

[type="file"]:focus {
  outline: 1px auto -webkit-focus-ring-color;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
  .xl\:flex-nowrap {
    flex-wrap: nowrap;
  }
  .xl\:flex-row {
    flex-direction: row;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1280px;
  }
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}

.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 213, 255, var(--tw-bg-opacity));
}

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(168, 85, 247, var(--tw-bg-opacity));
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}

.pl-10 {
  padding-left: 40px;
}

.px-20 {
  padding-left: 80px;
  padding-right: 80px;
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.bg-blue {
  background-color: #0095d9;
}
.bg-yellow {
  background-color: #f7d263;
}
.bg-orange {
  background-color: #ef611f;
}
.bg-red {
  background-color: #c91f1f;
}

.grid {
  display: grid;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.bg-lightBlue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(186, 230, 253, var(--tw-bg-opacity));
}

.bg-lightBlue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(125, 211, 252, var(--tw-bg-opacity));
}

.bg-lightBlue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(56, 189, 248, var(--tw-bg-opacity));
}

.bg-lightBlue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(14, 165, 233, var(--tw-bg-opacity));
}

.bg-lightBlue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 132, 199, var(--tw-bg-opacity));
}

.bg-green {
  --tw-bg-opacity: 1;
  background-color: #00b151;
}

.bg-lightBlue-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(7, 89, 133, var(--tw-bg-opacity));
}

.bg-lightBlue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(12, 74, 110, var(--tw-bg-opacity));
}

.bg-teal-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 246, 228, var(--tw-bg-opacity));
}

.bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 184, 166, var(--tw-bg-opacity));
}

.bg-emerald-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}

.bg-emerald-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}

.bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 179, 8, var(--tw-bg-opacity));
}

.bg-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 215, 170, var(--tw-bg-opacity));
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 115, 22, var(--tw-bg-opacity));
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}

.bg-blueGray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 250, 252, var(--tw-bg-opacity));
}

.bg-blueGray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
}

.bg-blueGray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}

.bg-blueGray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(71, 85, 105, var(--tw-bg-opacity));
}

.bg-blueGray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(51, 65, 85, var(--tw-bg-opacity));
}

.bg-blueGray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 41, 59, var(--tw-bg-opacity));
}

.active\:bg-indigo-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.active\:bg-lightBlue-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 132, 199, var(--tw-bg-opacity));
}

.active\:bg-blueGray-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 250, 252, var(--tw-bg-opacity));
}

.active\:bg-blueGray-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(71, 85, 105, var(--tw-bg-opacity));
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-cover {
  background-size: cover;
}

.bg-full {
  background-size: 100%;
}

.border-collapse {
  border-collapse: collapse;
}

.border-lightBlue-700 {
  --tw-border-opacity: 1;
  border-color: rgba(3, 105, 161, var(--tw-border-opacity));
}

.border-blueGray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(248, 250, 252, var(--tw-border-opacity));
}

.border-blueGray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(241, 245, 249, var(--tw-border-opacity));
}

.border-blueGray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(226, 232, 240, var(--tw-border-opacity));
}

.border-blueGray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(203, 213, 225, var(--tw-border-opacity));
}

.border-blueGray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(100, 116, 139, var(--tw-border-opacity));
}

.border-blueGray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(71, 85, 105, var(--tw-border-opacity));
}

.rounded {
  border-radius: 0.25rem;
}
bg-blueGray-100 .placeholder-red-600::placeholder {
  color: #fb3e3e;
  opacity: 0.6;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.border-0 {
  border-width: 0px;
}

.border-2 {
  border-width: 2px;
}

.border {
  border-width: 1px;
}

.border-t-0 {
  border-top-width: 0px;
}

.border-r-0 {
  border-right-width: 0px;
}

.border-l-0 {
  border-left-width: 0px;
}

.border-t {
  border-top-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.cursor-pointer {
  cursor: pointer;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.content-center {
  align-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-initial {
  flex: 0 1 auto;
}

.flex-grow {
  flex-grow: 1;
}

.float-left {
  float: left;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.h-0 {
  height: 0px;
}

.h-2 {
  height: 0.5rem;
}

.h-5 {
  height: 1.25rem;
}

.h-8 {
  height: 2rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.h-95-px {
  height: 95px;
}

.h-70-px {
  height: 70px;
}

.h-350-px {
  height: 350px;
}

.h-500-px {
  height: 500px;
}

.h-600-px {
  height: 600px;
}

.text-55 {
  font-size: 55rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.leading-none {
  line-height: 1;
}

.leading-snug {
  line-height: 1.375;
}

.leading-normal {
  line-height: 1.5;
}

.leading-relaxed {
  line-height: 1.625;
}

.list-none {
  list-style-type: none;
}

.m-2 {
  margin: 0.5rem;
}

.m-4 {
  margin: 1rem;
}

.-m-16 {
  margin: -4rem;
}

.-m-24 {
  margin: -6rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-0 {
  margin-top: 0px;
}

.mr-0 {
  margin-right: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mt-48 {
  margin-top: 12rem;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.-ml-4 {
  margin-left: -1rem;
}

.-mt-20 {
  margin-top: -5rem;
}

.-ml-20 {
  margin-left: -5rem;
}

.-mt-24 {
  margin-top: -6rem;
}

.-mt-32 {
  margin-top: -8rem;
}

.-mt-48 {
  margin-top: -12rem;
}

.-mt-64 {
  margin-top: -16rem;
}

.last\:mr-0:last-child {
  margin-right: 0px;
}

.hover\:-mt-4:hover {
  margin-top: -1rem;
}

.max-h-860-px {
  max-height: 860px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-100-px {
  max-width: 100px;
}

.max-w-120-px {
  max-width: 120px;
}

.max-w-150-px {
  max-width: 150px;
}

.max-w-180-px {
  max-width: 180px;
}

.max-w-200-px {
  max-width: 200px;
}

.max-w-210-px {
  max-width: 210px;
}

.max-w-580-px {
  max-width: 580px;
}

.min-h-screen {
  min-height: 100vh;
}

.min-h-screen-75 {
  min-height: 75vh;
}

.min-w-0 {
  min-width: 0px;
}

.min-w-48 {
  min-width: 12rem;
}

.min-w-140-px {
  min-width: 140px;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-80 {
  opacity: 0.8;
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.right-3 {
  right: 0.5 rem;
}

.nooutline {
  outline: 0px;
  outline-offset: 0px;
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-8 {
  padding: 2rem;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.pt-0 {
  padding-top: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pl-3 {
  padding-left: 0.75rem;
}
.pl-2 {
  padding-left: 0.75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.pt-48 {
  padding-top: 12rem;
}

.pb-48 {
  padding-bottom: 12rem;
}

.pb-64 {
  padding-bottom: 16rem;
}

.placeholder-blueGray-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(203, 213, 225, var(--tw-placeholder-opacity));
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.top-0 {
  top: 0px;
}

.right-0 {
  right: 0px;
}

.bottom-0 {
  bottom: 0px;
}

.left-0 {
  left: 0px;
}

.top-auto {
  top: auto;
}

.bottom-auto {
  bottom: auto;
}

.left-auto {
  left: auto;
}

.-right-100 {
  right: -100%;
}

.-top-225-px {
  top: -225px;
}

.-top-160-px {
  top: -160px;
}

.-top-150-px {
  top: -150px;
}

.-top-94-px {
  top: -94px;
}

.-left-50-px {
  left: -50px;
}

.-top-29-px {
  top: -29px;
}

.-left-20-px {
  left: -20px;
}

.top-25-px {
  top: 25px;
}

.left-40-px {
  left: 40px;
}

.top-95-px {
  top: 95px;
}

.left-145-px {
  left: 145px;
}

.left-195-px {
  left: 195px;
}

.top-210-px {
  top: 210px;
}

.left-260-px {
  left: 260px;
}

* {
  --tw-shadow: 0 0 #0000;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

* {
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.fill-current {
  fill: currentColor;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}

.text-lightBlue-300 {
  --tw-text-opacity: 1;
  color: rgba(125, 211, 252, var(--tw-text-opacity));
}

.text-lightBlue-400 {
  --tw-text-opacity: 1;
  color: rgba(56, 189, 248, var(--tw-text-opacity));
}

.text-lightBlue-500 {
  --tw-text-opacity: 1;
  color: rgba(14, 165, 233, var(--tw-text-opacity));
}

.gap-0 {
  gap: 0px;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-14 {
  gap: 3.5rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-9 {
  gap: 2.25rem;
}

.gap-\[11px\] {
  gap: 11px;
}

.gap-\[14px\] {
  gap: 14px;
}

.gap-\[32px\] {
  gap: 32px;
}

.gap-\[35px\] {
  gap: 35px;
}

.gap-\[40px\] {
  gap: 40px;
}

.gap-\[56px\] {
  gap: 56px;
}

.gap-\[68px\] {
  gap: 68px;
}

.gap-x-3 {
  -moz-column-gap: 0.75rem;
  column-gap: 0.75rem;
}

.gap-x-\[37px\] {
  -moz-column-gap: 37px;
  column-gap: 37px;
}

.gap-y-3 {
  row-gap: 0.75rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}

.px-\[11px\] {
  padding-left: 11px;
  padding-right: 11px;
}

.px-\[20px\] {
  padding-left: 20px;
  padding-right: 20px;
}

.px-\[28px\] {
  padding-left: 28px;
  padding-right: 28px;
}

.px-\[40px\] {
  padding-left: 40px;
  padding-right: 40px;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-\[120px\] {
  padding-top: 120px;
  padding-bottom: 120px;
}

.py-\[135px\] {
  padding-top: 135px;
  padding-bottom: 135px;
}

.py-\[13px\] {
  padding-top: 13px;
  padding-bottom: 13px;
}

.py-\[14\.5px\] {
  padding-top: 14.5px;
  padding-bottom: 14.5px;
}

.py-\[14px\] {
  padding-top: 14px;
  padding-bottom: 14px;
}

.py-\[30px\] {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px;
}

.py-\[7px\] {
  padding-top: 7px;
  padding-bottom: 7px;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pb-\[140px\] {
  padding-bottom: 140px;
}

.pb-\[149px\] {
  padding-bottom: 149px;
}

.pb-\[230px\] {
  padding-bottom: 230px;
}

.pb-\[32px\] {
  padding-bottom: 32px;
}

.pb-\[48px\] {
  padding-bottom: 48px;
}

.pb-\[65px\] {
  padding-bottom: 65px;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pr-0 {
  padding-right: 0px;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pr-3 {
  padding-right: 0.75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-\[132px\] {
  padding-right: 132px;
}

.pr-\[56px\] {
  padding-right: 56px;
}

.pt-0 {
  padding-top: 0px;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-\[100px\] {
  padding-top: 100px;
}

.pt-\[120px\] {
  padding-top: 120px;
}

.pt-\[56px\] {
  padding-top: 56px;
}

.pt-\[80px\] {
  padding-top: 80px;
}

.text-lightBlue-600 {
  --tw-text-opacity: 1;
  color: rgba(2, 132, 199, var(--tw-text-opacity));
}

.text-teal-500 {
  --tw-text-opacity: 1;
  color: rgba(20, 184, 166, var(--tw-text-opacity));
}

.text-emerald-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgba(249, 115, 22, var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.text-blueGray-100 {
  --tw-text-opacity: 1;
  color: rgba(241, 245, 249, var(--tw-text-opacity));
}

.text-blueGray-200 {
  --tw-text-opacity: 1;
  color: rgba(226, 232, 240, var(--tw-text-opacity));
}

.text-blueGray-300 {
  --tw-text-opacity: 1;
  color: rgba(203, 213, 225, var(--tw-text-opacity));
}

.text-blueGray-400 {
  --tw-text-opacity: 1;
  color: rgba(148, 163, 184, var(--tw-text-opacity));
}

.text-blueGray-500 {
  --tw-text-opacity: 1;
  color: rgba(100, 116, 139, var(--tw-text-opacity));
}

.text-blueGray-600 {
  --tw-text-opacity: 1;
  color: rgba(71, 85, 105, var(--tw-text-opacity));
}

.text-blueGray-700 {
  --tw-text-opacity: 1;
  color: rgba(51, 65, 85, var(--tw-text-opacity));
}

.text-blueGray-800 {
  --tw-text-opacity: 1;
  color: rgba(30, 41, 59, var(--tw-text-opacity));
}

.hover\:text-lightBlue-600:hover {
  --tw-text-opacity: 1;
  color: rgba(2, 132, 199, var(--tw-text-opacity));
}

.hover\:text-blueGray-300:hover {
  --tw-text-opacity: 1;
  color: rgba(203, 213, 225, var(--tw-text-opacity));
}

.hover\:text-blueGray-500:hover {
  --tw-text-opacity: 1;
  color: rgba(100, 116, 139, var(--tw-text-opacity));
}

.hover\:text-blueGray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(51, 65, 85, var(--tw-text-opacity));
}

.hover\:text-blueGray-800:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 41, 59, var(--tw-text-opacity));
}

.uppercase {
  text-transform: uppercase;
}

.no-underline {
  text-decoration: none;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tracking-wide {
  letter-spacing: 0.025em;
}

.align-middle {
  vertical-align: middle;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.w-5 {
  width: 1.25rem;
}

.w-4 {
  width: 1rem;
}
.h-4 {
  height: 1rem;
}

.w-2 {
  width: 0.5rem;
}

.w-8 {
  width: 2rem;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-auto {
  width: auto;
}

.w-1\/2 {
  width: 50%;
}

.w-6\/12 {
  width: 50%;
}

.w-10\/12 {
  width: 83.333333%;
}

.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-10 {
  z-index: 10;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}
.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.ease-linear {
  transition-timing-function: linear;
}

.duration-150 {
  transition-duration: 150ms;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

@media (min-width: 640px) {
  .sm\:block {
    display: block;
  }

  .sm\:mt-0 {
    margin-top: 0px;
  }

  .sm\:ml-1 {
    margin-left: 0.25rem;
  }

  .sm\:mr-2 {
    margin-right: 0.5rem;
  }

  .sm\:pt-0 {
    padding-top: 0px;
  }

  .sm\:w-6\/12 {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .md\:items-stretch {
    align-items: stretch;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:mt-0 {
    margin-top: 0px;
  }

  .md\:mt-4 {
    margin-top: 1rem;
  }

  .md\:mb-4 {
    margin-bottom: 1rem;
  }

  .md\:mt-40 {
    margin-top: 10rem;
  }

  .md\:mt-64 {
    margin-top: 16rem;
  }

  .md\:ml-64 {
    margin-left: 16rem;
  }

  .md\:min-h-full {
    min-height: 100%;
  }

  .md\:min-w-full {
    min-width: 100%;
  }

  .md\:opacity-100 {
    opacity: 1;
  }

  .md\:overflow-hidden {
    overflow: hidden;
  }

  .md\:overflow-y-auto {
    overflow-y: auto;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:pt-0 {
    padding-top: 0px;
  }

  .md\:pb-2 {
    padding-bottom: 0.5rem;
  }

  .md\:pr-12 {
    padding-right: 3rem;
  }

  .md\:pt-32 {
    padding-top: 8rem;
  }

  .md\:fixed {
    position: fixed;
  }

  .md\:relative {
    position: relative;
  }

  .md\:top-0 {
    top: 0px;
  }

  .md\:bottom-0 {
    bottom: 0px;
  }

  .md\:left-0 {
    left: 0px;
  }

  .md\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:w-64 {
    width: 16rem;
  }

  .md\:w-4\/12 {
    width: 33.333333%;
  }

  .md\:w-5\/12 {
    width: 41.666667%;
  }

  .md\:w-6\/12 {
    width: 50%;
  }

  .md\:w-8\/12 {
    width: 66.666667%;
  }
}

@media (min-width: 1024px) {
  .lg\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:self-center {
    align-self: center;
  }
  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:order-1 {
    order: 1;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:order-3 {
    order: 3;
  }

  .lg\:mb-0 {
    margin-bottom: 0px;
  }

  .lg\:mr-1 {
    margin-right: 0.25rem;
  }

  .lg\:mr-4 {
    margin-right: 1rem;
  }

  .lg\:mt-16 {
    margin-top: 4rem;
  }

  .lg\:ml-auto {
    margin-left: auto;
  }

  .lg\:-ml-16 {
    margin-left: -4rem;
  }

  .lg\:-mt-64 {
    margin-top: -16rem;
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:pt-0 {
    padding-top: 0px;
  }

  .lg\:pt-4 {
    padding-top: 1rem;
  }

  .lg\:pt-12 {
    padding-top: 3rem;
  }

  .lg\:pt-24 {
    padding-top: 6rem;
  }

  .lg\:pb-64 {
    padding-bottom: 16rem;
  }

  .lg\:static {
    position: static;
  }

  .lg\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-right {
    text-align: right;
  }

  .lg\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .lg\:text-blueGray-200 {
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }

  .lg\:hover\:text-blueGray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-3\/12 {
    width: 25%;
  }

  .lg\:w-4\/12 {
    width: 33.333333%;
  }

  .lg\:w-6\/12 {
    width: 50%;
  }

  .lg\:w-8\/12 {
    width: 66.666667%;
  }

  .lg\:w-9\/12 {
    width: 75%;
  }
}

@media (min-width: 1280px) {
  .xl\:mb-0 {
    margin-bottom: 0px;
  }

  .xl\:w-3\/12 {
    width: 25%;
  }
  .xl\:flex-col {
    flex-direction: column;
  }

  .xl\:w-4\/12 {
    width: 33.333333%;
  }

  .xl\:w-6\/12 {
    width: 50%;
  }

  .xl\:w-8\/12 {
    width: 66.666667%;
  }
}

@media (min-width: 1536px) {
}

@media (min-width: 640px) {
  .sm\:left-\[-50px\] {
    left: -50px;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .md\:mt-8 {
    margin-top: 2rem;
  }

  .md\:w-2\/4 {
    width: 50%;
  }

  .md\:w-\[100px\] {
    width: 100px;
  }

  .md\:w-\[319px\] {
    width: 319px;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-items-start {
    justify-items: start;
  }

  .md\:gap-12 {
    gap: 3rem;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .md\:px-\[13px\] {
    padding-left: 13px;
    padding-right: 13px;
  }

  .md\:px-\[84px\] {
    padding-left: 84px;
    padding-right: 84px;
  }

  .md\:pl-3 {
    padding-left: 0.75rem;
  }

  .md\:pr-10 {
    padding-right: 2.5rem;
  }

  .md\:text-left {
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .lg\:static {
    position: static;
  }

  .lg\:\!absolute {
    position: absolute !important;
  }

  .lg\:absolute {
    position: absolute;
  }

  .lg\:\!-top-\[9rem\] {
    top: -9rem !important;
  }

  .lg\:-bottom-\[15rem\] {
    bottom: -15rem;
  }

  .lg\:-left-\[2\.1rem\] {
    left: -2.1rem;
  }

  .lg\:-right-\[100px\] {
    right: -100px;
  }

  .lg\:left-20 {
    left: 5rem;
  }

  .lg\:left-\[-30px\] {
    left: -30px;
  }

  .lg\:left-auto {
    left: auto;
  }

  .lg\:right-0 {
    right: 0px;
  }

  .lg\:right-20 {
    right: 5rem;
  }

  .lg\:right-6 {
    right: 1.5rem;
  }

  .lg\:right-\[94px\] {
    right: 94px;
  }

  .lg\:top-2\/4 {
    top: 50%;
  }

  .lg\:top-6 {
    top: 1.5rem;
  }

  .lg\:top-\[4\.85rem\] {
    top: 4.85rem;
  }

  .lg\:top-auto {
    top: auto;
  }

  .lg\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .lg\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .lg\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .lg\:my-\[120px\] {
    margin-top: 120px;
    margin-bottom: 120px;
  }

  .lg\:my-\[80px\] {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .lg\:-mt-\[40px\] {
    margin-top: -40px;
  }

  .lg\:mb-0 {
    margin-bottom: 0px;
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg\:mb-16 {
    margin-bottom: 4rem;
  }

  .lg\:mb-\[104px\] {
    margin-bottom: 104px;
  }

  .lg\:mb-\[105px\] {
    margin-bottom: 105px;
  }

  .lg\:mb-\[120px\] {
    margin-bottom: 120px;
  }

  .lg\:mb-\[176px\] {
    margin-bottom: 176px;
  }

  .lg\:mb-\[96px\] {
    margin-bottom: 96px;
  }

  .lg\:ml-2 {
    margin-left: 0.5rem;
  }

  .lg\:ml-\[30px\] {
    margin-left: 30px;
  }

  .lg\:mt-0 {
    margin-top: 0px;
  }

  .lg\:mt-20 {
    margin-top: 5rem;
  }

  .lg\:mt-\[104px\] {
    margin-top: 104px;
  }

  .lg\:mt-\[10px\] {
    margin-top: 10px;
  }

  .lg\:mt-\[120px\] {
    margin-top: 120px;
  }

  .lg\:mt-\[133px\] {
    margin-top: 133px;
  }

  .lg\:mt-\[151px\] {
    margin-top: 151px;
  }

  .lg\:mt-\[164px\] {
    margin-top: 164px;
  }

  .lg\:mt-\[166px\] {
    margin-top: 166px;
  }

  .lg\:mt-\[64px\] {
    margin-top: 64px;
  }

  .lg\:\!block {
    display: block !important;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:\!hidden {
    display: none !important;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:\!h-\[560px\] {
    height: 560px !important;
  }

  .lg\:h-\[120px\] {
    height: 120px;
  }

  .lg\:h-\[248px\] {
    height: 248px;
  }

  .lg\:h-\[80px\] {
    height: 80px;
  }

  .lg\:h-\[820px\] {
    height: 820px;
  }

  .lg\:\!w-\[896px\] {
    width: 896px !important;
  }

  .lg\:w-2\/4 {
    width: 50%;
  }

  .lg\:w-8 {
    width: 2rem;
  }

  .lg\:w-\[150px\] {
    width: 150px;
  }

  .lg\:w-\[200px\] {
    width: 200px;
  }

  .lg\:w-\[30\%\] {
    width: 30%;
  }

  .lg\:w-\[300px\] {
    width: 300px;
  }

  .lg\:w-\[352px\] {
    width: 352px;
  }

  .lg\:w-\[397px\] {
    width: 397px;
  }

  .lg\:w-\[40\%\] {
    width: 40%;
  }

  .lg\:w-\[400px\] {
    width: 400px;
  }

  .lg\:w-\[45\%\] {
    width: 45%;
  }

  .lg\:w-\[47\.5\%\] {
    width: 47.5%;
  }

  .lg\:w-\[50\%\] {
    width: 50%;
  }

  .lg\:w-\[500px\] {
    width: 500px;
  }

  .lg\:w-\[52\.5\%\] {
    width: 52.5%;
  }

  .lg\:w-\[55\%\] {
    width: 55%;
  }

  .lg\:w-\[550px\] {
    width: 550px;
  }

  .lg\:w-\[60\%\] {
    width: 60%;
  }

  .lg\:w-\[600px\] {
    width: 600px;
  }

  .lg\:w-\[70\%\] {
    width: 70%;
  }

  .lg\:w-\[776px\] {
    width: 776px;
  }

  .lg\:w-\[85\%\] {
    width: 85%;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-fit {
    width: -moz-fit-content;
    width: fit-content;
  }

  .lg\:min-w-\[350px\] {
    min-width: 350px;
  }

  .lg\:min-w-\[615px\] {
    min-width: 615px;
  }

  .lg\:max-w-\[1084px\] {
    max-width: 1084px;
  }

  .lg\:max-w-\[1200px\] {
    max-width: 1200px;
  }

  .lg\:max-w-\[120px\] {
    max-width: 120px;
  }

  .lg\:max-w-\[1512px\] {
    max-width: 1512px;
  }

  .lg\:max-w-\[280px\] {
    max-width: 280px;
  }

  .lg\:max-w-\[332px\] {
    max-width: 332px;
  }

  .lg\:max-w-\[340px\] {
    max-width: 340px;
  }

  .lg\:max-w-\[343px\] {
    max-width: 343px;
  }

  .lg\:max-w-\[376px\] {
    max-width: 376px;
  }

  .lg\:max-w-\[381px\] {
    max-width: 381px;
  }

  .lg\:max-w-\[391px\] {
    max-width: 391px;
  }

  .lg\:max-w-\[393px\] {
    max-width: 393px;
  }

  .lg\:max-w-\[400px\] {
    max-width: 400px;
  }

  .lg\:max-w-\[500px\] {
    max-width: 500px;
  }

  .lg\:max-w-\[588px\] {
    max-width: 588px;
  }

  .lg\:max-w-\[720px\] {
    max-width: 720px;
  }

  .lg\:max-w-\[800px\] {
    max-width: 800px;
  }

  .lg\:-translate-x-\[120px\] {
    --tw-translate-x: -120px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-x-\[5\%\] {
    --tw-translate-x: 5%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-0 {
    gap: 0px;
  }

  .lg\:gap-10 {
    gap: 2.5rem;
  }

  .lg\:gap-14 {
    gap: 3.5rem;
  }

  .lg\:gap-16 {
    gap: 4rem;
  }

  .lg\:gap-20 {
    gap: 5rem;
  }

  .lg\:gap-3 {
    gap: 0.75rem;
  }

  .lg\:gap-5 {
    gap: 1.25rem;
  }

  .lg\:gap-\[100px\] {
    gap: 100px;
  }

  .lg\:gap-\[16px\] {
    gap: 16px;
  }

  .lg\:gap-\[21px\] {
    gap: 21px;
  }

  .lg\:gap-\[28px\] {
    gap: 28px;
  }

  .lg\:gap-\[30px\] {
    gap: 30px;
  }

  .lg\:gap-\[40px\] {
    gap: 40px;
  }

  .lg\:gap-\[60px\] {
    gap: 60px;
  }

  .lg\:gap-\[72px\] {
    gap: 72px;
  }

  .lg\:gap-y-20 {
    row-gap: 5rem;
  }

  .lg\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .lg\:whitespace-nowrap {
    white-space: nowrap;
  }

  .lg\:rounded-bl-\[164px\] {
    border-bottom-left-radius: 164px;
  }

  .lg\:rounded-br-\[200px\] {
    border-bottom-right-radius: 200px;
  }

  .lg\:rounded-tl-\[164px\] {
    border-top-left-radius: 164px;
  }

  .lg\:rounded-tl-\[200px\] {
    border-top-left-radius: 200px;
  }

  .lg\:rounded-tr-\[200px\] {
    border-top-right-radius: 200px;
  }

  .lg\:border-none {
    border-style: none;
  }

  .lg\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .lg\:px-\[120px\] {
    padding-left: 120px;
    padding-right: 120px;
  }

  .lg\:px-\[156px\] {
    padding-left: 156px;
    padding-right: 156px;
  }

  .lg\:px-\[20px\] {
    padding-left: 20px;
    padding-right: 20px;
  }

  .lg\:px-\[24px\] {
    padding-left: 24px;
    padding-right: 24px;
  }

  .lg\:px-\[40px\] {
    padding-left: 40px;
    padding-right: 40px;
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:py-\[104px\] {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  .lg\:py-\[120px\] {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .lg\:py-\[20px\] {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .lg\:pb-0 {
    padding-bottom: 0px;
  }

  .lg\:pb-16 {
    padding-bottom: 4rem;
  }

  .lg\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .lg\:pb-\[120px\] {
    padding-bottom: 120px;
  }

  .lg\:pb-\[132px\] {
    padding-bottom: 132px;
  }

  .lg\:pb-\[136px\] {
    padding-bottom: 136px;
  }

  .lg\:pb-\[160px\] {
    padding-bottom: 160px;
  }

  .lg\:pb-\[188px\] {
    padding-bottom: 188px;
  }

  .lg\:pb-\[196px\] {
    padding-bottom: 196px;
  }

  .lg\:pl-0 {
    padding-left: 0px;
  }

  .lg\:pl-16 {
    padding-left: 4rem;
  }

  .lg\:pl-20 {
    padding-left: 5rem;
  }

  .lg\:pl-\[122px\] {
    padding-left: 122px;
  }

  .lg\:pl-\[128px\] {
    padding-left: 128px;
  }

  .lg\:pl-\[180px\] {
    padding-left: 180px;
  }

  .lg\:pl-\[66px\] {
    padding-left: 66px;
  }

  .lg\:pl-\[72px\] {
    padding-left: 72px;
  }

  .lg\:pr-0 {
    padding-right: 0px;
  }

  .lg\:pr-20 {
    padding-right: 5rem;
  }

  .lg\:pr-\[600px\] {
    padding-right: 600px;
  }

  .lg\:pt-0 {
    padding-top: 0px;
  }

  .lg\:pt-\[104px\] {
    padding-top: 104px;
  }

  .lg\:pt-\[120px\] {
    padding-top: 120px;
  }

  .lg\:pt-\[122px\] {
    padding-top: 122px;
  }

  .lg\:pt-\[125px\] {
    padding-top: 125px;
  }

  .lg\:pt-\[128px\] {
    padding-top: 128px;
  }

  .lg\:pt-\[144px\] {
    padding-top: 144px;
  }

  .lg\:pt-\[56px\] {
    padding-top: 56px;
  }

  .lg\:pt-\[60px\] {
    padding-top: 60px;
  }

  .lg\:pt-\[88px\] {
    padding-top: 88px;
  }

  .lg\:pt-\[96px\] {
    padding-top: 96px;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:text-\[16px\] {
    font-size: 16px;
  }

  .lg\:text-\[24px\] {
    font-size: 24px;
  }

  .lg\:text-\[36px\] {
    font-size: 36px;
  }

  .lg\:text-\[48px\] {
    font-size: 48px;
  }

  .lg\:text-\[52px\] {
    font-size: 52px;
  }

  .lg\:text-\[54px\] {
    font-size: 54px;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:font-semibold {
    font-weight: 600;
  }

  .lg\:leading-6 {
    line-height: 1.5rem;
  }

  .lg\:leading-\[34px\] {
    line-height: 34px;
  }

  .lg\:leading-\[50px\] {
    line-height: 50px;
  }

  .lg\:leading-\[60\.79px\] {
    line-height: 60.79px;
  }

  .lg\:leading-normal {
    line-height: 1.5;
  }

  .lg\:text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }

  .lg\:before\:top-\[110px\]::before {
    content: var(--tw-content);
    top: 110px;
  }

  .lg\:before\:block::before {
    content: var(--tw-content);
    display: block;
  }

  .group:hover .group-hover\:lg\:block {
    display: block;
  }

  .group:hover .lg\:group-hover\:flex {
    display: flex;
  }

  .group:hover .lg\:group-hover\:justify-between {
    justify-content: space-between;
  }

  .group:hover .group-hover\:lg\:before\:w-full::before {
    content: var(--tw-content);
    width: 100%;
  }
}

@media (min-width: 1280px) {
  .xl\:-left-\[4\.6rem\] {
    left: -4.6rem;
  }

  .xl\:-right-\[170px\] {
    right: -170px;
  }

  .xl\:-right-\[180px\] {
    right: -180px;
  }

  .xl\:-top-5 {
    top: -1.25rem;
  }

  .xl\:left-\[6rem\] {
    left: 6rem;
  }

  .xl\:right-\[120px\] {
    right: 120px;
  }

  .xl\:mx-\[164px\] {
    margin-left: 164px;
    margin-right: 164px;
  }

  .xl\:-mt-\[65px\] {
    margin-top: -65px;
  }

  .xl\:ml-\[80px\] {
    margin-left: 80px;
  }

  .xl\:mt-\[90px\] {
    margin-top: 90px;
  }

  .xl\:h-\[112px\] {
    height: 112px;
  }

  .xl\:w-\[298px\] {
    width: 298px;
  }

  .xl\:w-\[35\%\] {
    width: 35%;
  }

  .xl\:w-\[408px\] {
    width: 408px;
  }

  .xl\:w-\[496px\] {
    width: 496px;
  }

  .xl\:w-\[545\.999px\] {
    width: 545.999px;
  }

  .xl\:w-\[588px\] {
    width: 588px;
  }

  .xl\:w-\[65\%\] {
    width: 65%;
  }

  .xl\:w-\[700px\] {
    width: 700px;
  }

  .xl\:w-full {
    width: 100%;
  }

  .xl\:min-w-\[448px\] {
    min-width: 448px;
  }

  .xl\:max-w-\[100\%\] {
    max-width: 100%;
  }

  .xl\:max-w-\[332px\] {
    max-width: 332px;
  }

  .xl\:max-w-\[423px\] {
    max-width: 423px;
  }

  .xl\:max-w-\[520px\] {
    max-width: 520px;
  }

  .xl\:max-w-full {
    max-width: 100%;
  }

  .xl\:translate-x-\[15\%\] {
    --tw-translate-x: 15%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .xl\:gap-0 {
    gap: 0px;
  }

  .xl\:gap-12 {
    gap: 3rem;
  }

  .xl\:gap-20 {
    gap: 5rem;
  }

  .xl\:gap-8 {
    gap: 2rem;
  }

  .xl\:gap-\[100px\] {
    gap: 100px;
  }

  .xl\:gap-\[104px\] {
    gap: 104px;
  }

  .xl\:gap-\[108px\] {
    gap: 108px;
  }

  .xl\:gap-\[120px\] {
    gap: 120px;
  }

  .xl\:gap-\[168px\] {
    gap: 168px;
  }

  .xl\:gap-\[196px\] {
    gap: 196px;
  }

  .xl\:gap-\[200px\] {
    gap: 200px;
  }

  .xl\:gap-\[32px\] {
    gap: 32px;
  }

  .xl\:gap-\[64px\] {
    gap: 64px;
  }

  .xl\:gap-\[80px\] {
    gap: 80px;
  }

  .xl\:gap-\[84px\] {
    gap: 84px;
  }

  .xl\:p-0 {
    padding: 0px;
  }

  .xl\:px-\[120px\] {
    padding-left: 120px;
    padding-right: 120px;
  }

  .xl\:px-\[148px\] {
    padding-left: 148px;
    padding-right: 148px;
  }

  .xl\:px-\[156px\] {
    padding-left: 156px;
    padding-right: 156px;
  }

  .xl\:px-\[168px\] {
    padding-left: 168px;
    padding-right: 168px;
  }

  .xl\:px-\[240px\] {
    padding-left: 240px;
    padding-right: 240px;
  }

  .xl\:px-\[276px\] {
    padding-left: 276px;
    padding-right: 276px;
  }

  .xl\:px-\[40px\] {
    padding-left: 40px;
    padding-right: 40px;
  }

  .xl\:px-\[48px\] {
    padding-left: 48px;
    padding-right: 48px;
  }

  .xl\:px-\[64px\] {
    padding-left: 64px;
    padding-right: 64px;
  }

  .xl\:px-\[80px\] {
    padding-left: 80px;
    padding-right: 80px;
  }

  .xl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .xl\:py-\[30px\] {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .xl\:pl-\[120px\] {
    padding-left: 120px;
  }

  .xl\:pl-\[232px\] {
    padding-left: 232px;
  }

  .xl\:pr-\[120px\] {
    padding-right: 120px;
  }

  .xl\:pr-\[444px\] {
    padding-right: 444px;
  }

  .xl\:pr-\[64px\] {
    padding-right: 64px;
  }

  .xl\:text-\[54px\] {
    font-size: 54px;
  }

  .xl\:leading-\[68\.04px\] {
    line-height: 68.04px;
  }
}
