.project_dropdown{
    top: 34px;
    left: 0px;
}


.dropdown_button_hover{
    &:hover{
        background-color: #0284C7;
        color: white;
        >svg{
            path{
                fill: white;
            }
        }
    }
}

.modal-overlay{
    --tw-bg-opacity: 1;
    background-color:rgb(24 24 24 / 68%);
}

.modal_show{
    opacity: 1;
    visibility: visible;
    z-index: 50;
}


.not_modal_show{
    opacity: 0;
    visibility: hidden;
}

.modal-wrapper{
    position: relative;
    background-color: white;
    color: black;
    z-index: 100;
    height: fit-content;
    width: fit-content;
    border-radius:15px ;
    margin: auto auto;
    padding: 30px 30px;
    @media (min-width:1024px) {
        min-width: 500px;
        min-height: 250px;
    }
}

.button_close{
    position: absolute;
    top: 20px;
    right: 30px;
    padding: 3px 10px;
    border-radius: 999px;
    background-color:#0284C7 ;
    opacity: 1;

    i{
        color: white;
    }
    &:hover{
        opacity: 0.9;

    }
}

.button_class_delete{
    border: 1px solid rgb(251, 42, 42);
    color: rgb(251, 42, 42);
    opacity: 1;
    >svg{
        path{
            fill:rgb(251, 42, 42);
        }
    }
&:hover{
    outline: none;
    opacity: 0.8;
}

&:active{
    outline: none;
}
&:focus{
    outline: none;
}
}
.dropdown_button_hover.addition{
    background-color:#0284C7;
    color: white;
    >svg{
        fill: white;

    }
    opacity: 1;
    &:hover{
        opacity: 0.7;
    }
}