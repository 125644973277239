.histogram-container {
  min-height: 300px;
}

.rooot_class_skeketon_chart {
  min-height: 400px;
}

.historgram_chat_skeleton {
  height: 250px;
  padding: 0px 40px 0px 40px;
  opacity: 0.6;
  margin: 0px 10px 40px 10px;
  border-left: 2px solid rgb(157, 157, 157);
  border-bottom: 2px solid rgb(157, 157, 157);
  display: flex;
  gap: 4px;
  align-items: end;
  justify-content: center;
}

.skeleton-loader {
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    lightgray;
  background-repeat: repeat-y;
  background-size: 100% 700px;
  background-position: 0 0;
  animation: shine 2s infinite;
}
@keyframes shine {
  to {
    background-position: 0 100%, /* move highlight to right */ 0 0;
  }
}

.vertical_parent_class {
  width: 40px;
  > span {
    border-top: 2px solid rgb(157, 157, 157);
    border-left: 2px solid rgb(157, 157, 157);
    border-right: 2px solid rgb(157, 157, 157);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
}

.hxs {
  height: 20%;
}
.hs {
  height: 40%;
}
.hm {
  height: 60%;
}
.hl {
  height: 80%;
}
.hxl {
  height: 100%;
}
.backgound_overlay_color{
background-color: #00000036;
} 

// .apexcharts-inner.apexcharts-graphical{
//   max-width: 395px;
//   width: 100%;
// }

