.table_container {
  padding: 16px 16px;
  height: auto;

  @media (min-width: 1024px) {
    padding: 40px 20px;
  }

  > div {
    @media (min-width: 770px) and (max-width: 1024px) {
      margin-top: 90px;
    }
    @media (min-width: 1024px) {
      margin-top: 60px;
    }
  }
}

.p-highlight {
  background: #eef2ff;
  border-color: #eef2ff;
  color: #4338ca;
  &:focus {
    outline: 0px;
    border: 0px;
  }

  &:active {
    outline: 0px;
    border: 0px;
  }
}

.p-paginator-page {
  &:hover {
    background: #d8def0;
    border-color: #d8def0;
    color: #1a06f5;
  }
  &:focus {
    outline: 0px;
    border: 0px;
  }
  &:active {
    outline: 0px;
    border: 0px;
  }
}



.p-datatable-tbody {
  tr {
    &:hover {
      background-color: #eef2ff;
    }
  }
}

.p-column-title {
  white-space: nowrap;
  font-size: 14px;

  font-weight: normal;
}

.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled {
  border: 1px solid black;
  &:hover {
    background: #f4f4f4;
    border-color: #f4f4f4;
    border: 1px solid black;
  }
  &:focus {
    outline: 0px;
  }
  &:active {
    outline: 0px;
  }
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
}
.p-dropdown-item {
  &:hover {
    background: #d8def0;
    border-color: #d8def0;
    color: #1a06f5;
  }
}

.p-datatable .p-datatable-tbody > tr > td{
  border: 1px solid #e5e7eb;
  cursor: pointer;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-size: 13px;
  text-align: center;
  >div{
    text-align: left;
  }
}

.csv-export-button{
  color: white;
  background-color: rgb(57, 186, 237);
}
.p-column-filter{
  min-width: 100px !important;
  width: 130px  !important;
  align-items:  center;
  
}
.p-column-filter .p-column-filter-menu-button {
  margin-right: -20px;
}
.p-dropdown{
  width: 100px !important; 
  min-width: 150px !important;
  >svg{
    right: 24px;
  }
}
.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled{
  min-width: 100px;
  width: 150px ;
}
.p-dropdown .p-dropdown-trigger{
  width: 24px;
  height: 24px;
}
.p-inputtext{
  border-radius: 6px;
  border: none;
  font-size: 13px;

}
.p-column-header-content{
  justify-content: space-between;
}

.p-paginator-bottom {
  margin-top: 30px;
  >div{
   align-items: center;
   width: 70px !important;
   min-width: 70px !important;
  }
  button {
    &:hover {
      background: #d8def0;
      border-color: #d8def0;
      color: #1a06f5;
    }
    &:focus {
      outline: 0px;
      border: 0px;
    }
    &:active {
      outline: 0px;
      border: 0px;
    }
  }
}
.p-dropdown-item-label{
  font-size: 13px;
}

.p-column-filter-clear-button{
  display: none;
}

.p-tooltip-top{

  width: 270px;
  font-size: 13px;
  text-align: center;
}
.project-nested-table .p-datatable-thead{
display: none;

}
.project-nested-table .p-datatable-header{
display: none;

}

.main_container_headers:hover .tooltip_main_para {
 display: block;
}
.tooltip_main_para{
  z-index: 10;
  display: none;
  background-color: rgb(53, 60, 77);
  text-align: center;
  color: white;
  position: absolute;
  font-size: 13px;
  text-transform: none !important;
  font-weight: normal;
  
  padding: 4px 8px;
  border-radius: 4px;
  top: 100px;
  transform: translateX(-50%);
  width: 270px;
  left: 50%;
}
.main_container_headers_2:hover .tooltip_main_para_card_chart {
 display: block;
}
.tooltip_main_para_card_chart{
  z-index: 10;
  display: none;
  background-color: rgb(53, 60, 77);
  text-align: center;
  color: white;
  position: absolute;
  font-size: 13px;
  font-weight: normal;
  text-transform: none !important;
  padding: 4px 8px;
  border-radius: 4px;
  bottom: 55px;
  transform: translateX(-50%);
  width: 270px;
  left: 50%;
}

.inline-tooltip-img{
  cursor: pointer;
  display: inline;
}


.p-column-filter-row-item:nth-last-child(1){
color: rgba(255, 255, 255, 0);
font-size: 0px;
}
.p-column-filter-row-item:nth-last-child(1)::after{
 content: "No FIlters / Regex";
 display: block;
 color: #4b5563;
 font-size: 16px;

}