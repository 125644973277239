.child_class{
    top: 176px;
}

.form-overlay{
    background-color: #00000083;
}

.z-parent-div{
z-index: 9999;
}


.z-index-root-class{
z-index: 1;
}