.createView_parent_container {
  padding: 0px 16px;
  padding-top: 35px !important;

  @media (min-width: 770px) {
    padding-top: 110px !important;
  }
  @media (min-width: 1024px) {
    padding: 0px 40px;
  }
}

.project_view_parent {
  margin-bottom: 60px;
}
.bg-color_diabled {
  background-color: rgb(205, 203, 203);
}

.button_class_check {
  &:disabled {
    background-color: rgb(205, 203, 203);
  }
}
