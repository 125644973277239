.sidebar_image_responsive {
  width: 25px;
  height: 25px;
}
.mobile_top_nav {
  a {
    width: 75% !important;
  }
  button {
    width: 25% !important;
  }
}


.anchor_active_visited{
  outline: 0px solid transparent !important;
  i{
    &::before{
      color: #334155 !important;

    }
  }
  &:visited{
    color: inherit !important;
    text-decoration: none;
  }
  &:link {
    color: #0284CB !important;
  }
  &:active  {
    color: #0284CB !important;
  }
  &:hover{
    color: #0284CB !important;
    i{
      &::before{
        color: #0284CB !important;

      }
    }

  }
}

.button_style{
  &:focus{
    outline: 0px solid transparent !important;
    border: none;
  }
  &:active{
    outline: 0px solid transparent !important;
border: none;
  }
    i{
      &::before{
        color: #0284CB !important;

      }
    }
 
}
