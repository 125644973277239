.button_copy {
    background-color: #0284C7;
    border-radius: 10px;
    color: white;

    >svg {
        fill: white;

    }

    opacity: 1;

    &:hover {
        opacity: 0.7;
    }
}



.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    box-shadow: none !important;
    outline: 0px !important;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.active>.slider {
    background-color: #0284c7;
}

.active>.slider {
    box-shadow: 0 0 1px #0284c7;
}

.active>.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.disabled {
    background-color: #cccccc4d;

    td {
        user-select: none;
        tr{
            padding-top: 20px;
        }
        
    }
}

.enabled {
    background-color: none;
}

.default {
    border-bottom: 2px solid rgb(229, 229, 229);
    cursor: pointer;
.style_link{
   >p{
    background-color: #F1F5F9;
    border-radius: 60px;
    padding: 5px 20px;
   }
   .svg-logo{
    display: none;
   }
}
.style_link_copy{
    cursor: pointer;
   >p{
    background-color: #0284C7;

    color: white;
    padding: 5px 20px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
   }
   .svg-logo{
    display: block;
    position: absolute;
    top: 5px;
    left: 15px;
    background-color: #0284C7;
    padding: 5px 5px;
    border-radius: 99999px; 
    svg{
        fill: white;
    }
   }
 
}
}

.form_child_class{
    >input{
        border-bottom-left-radius: 25px;
        border-top-left-radius: 25px;
        border-left: 2px solid #8c8c8c;
        border-top: 2px solid #8c8c8c;
        border-bottom: 2px solid #8c8c8c;
        outline: 0px !important;
        box-shadow: none !important;
        &:focus{
            border-left: 2px solid #0284C7;
            border-top: 2px solid #0284C7;
            border-bottom: 2px solid #0284C7;
        }
        &:active{
            border-left: 2px solid #0284C7;
            border-top: 2px solid #0284C7;
            border-bottom: 2px solid #0284C7;
        }
    }

}

.form_class{
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
    padding: 10px 20px;
    @media (min-width:1024px) {
        padding: 10px 0px;
        
    }
}

.loading-occur{
    background-color: #b8b8b8;
    color: white;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    border-right: 2px solid #8c8c8c;
    border-top: 2px solid #8c8c8c;
    border-bottom: 2px solid #8c8c8c;
cursor: not-allowed
}

.loading-default{
    background-color: #0284C7;
    color: white;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
}