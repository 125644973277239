.pieChartContainer {
  max-width: 100%;
  height: 250px;

  @media (min-width: 400px) {
    height: 300px;
  }
  @media (min-width: 500px) {
    height: 400px;
  }

  @media (min-width: 1024px) {
    min-width: 315px;
    max-width: 100%;
  }

  .apexcharts-canvas,
  .apexchartssq2yr5ci,
  .apexcharts-theme-light {
    height: 100% !important;
    min-height: 250px !important;
    width: 100% !important;
    // height: 250px !important;

    // @media (min-width: 500px) {
    //   height: 100% !important;
    // }
    > svg {
      height: 100% !important;
      min-height: 250px !important;
      width: 100% !important;  
      >foreignObject{
        width: 100%  !important;
        height: 100% !important;
      }   
     }
  }
}

.arrow_box {
  position: relative;
  background: white;
  box-shadow: -1px 6px 44px 3px rgba(102, 102, 102, 0.68);
  -webkit-box-shadow: -1px 6px 44px 3px rgba(102, 102, 102, 0.68);
  -moz-box-shadow: -1px 6px 44px 3px rgba(102, 102, 102, 0.68);
  padding: 4px;
  color: white;
  width: fit-content;
  font-weight: 600;
}
.arrow_box:after,
.arrow_box:before {
  right: 100%;
  top: 50%;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  margin-top: -10px;
}
.arrow_box:before {
  margin-top: -13px;
}

#chart .apexcharts-tooltip {
  color: black;
  transform: translateX(10px) translateY(10px);
  overflow: visible !important;
  white-space: normal !important;
}

#chart .apexcharts-tooltip span {
  padding: 5px 10px;
  display: inline-block;
}

.apexcharts-legend.apexcharts-align-center.apx-legend-position-bottom {
  display: flex;
  flex-direction: row;
  gap: 4px;
}


