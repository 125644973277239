  .dlink {
    text-decoration: none !important;
    color: #03a9f3 !important;
    text-wrap: nowrap;
  }
  
  .data-table-extensions-filter {
    border: 1px solid lightgray;
    border-radius: 50px;
    padding: 3px 5px;
    width: 70%;
  }
  
  .first {
    padding-right: 10px !important;
  }
  
  .filter-text {
    border: none !important;
    width: 80%;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  

  .w-right{
    width: 80%;
  }

  .w-left{
text-wrap: nowrap;
    width: 20%;
  }

  .sc-lllnCg.jNGebf{
    svg{
        display: none;
    }
  }

.z-index-class{
    z-index: 1000;
}

.sc-dmcuvF.jVkDcp{
max-height: none;
}

.data-table-extensions-filter{
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: start;

    &:hover{
        border: 1px solid black;

    }
  
  
    .filter-text{
        font-size: 18px;
        font-weight: 400;
        font-family: sans-serif;
        &:active{
            outline: 0px !important;
            box-shadow: none;
       }
        &:focus{
            box-shadow: none;
            outline: 0px !important;
        }
    }
}


.data-table-extensions{
background-color: white;
}

.Table_container_class{
  .rdt_TableCell{
    justify-content: center;
  } 
}

.rdt_TableCol{
  justify-content: center;
}

.class_heading{
  top: 13px;
  left: 40px;
  font-family: sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}

.rdt_TableCol{
  padding-top: 5px;
  >div{
    .rdt_TableCol_Sortable{
      .sc-eDVOW.ftzeFU{
        font-size: 16px;
        line-height: 24px;
        font-family: sans-serif;
        font-weight: 400;
      }
    }
div:nth-child(2){
  input{
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px 10px;
    border-radius: 4px;
    box-shadow: 2px 2px 5px 3px rgba(0,0,0,0.2);
    -webkit-box-shadow: 2px 2px 5px 3px rgba(0,0,0,0.2);
    -moz-box-shadow: 2px 2px 5px 3px rgba(0,0,0,0.2);
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: rgb(67, 67, 67);
    font-family: sans-serif;

    &::placeholder{
      color: rgb(180, 180, 180);
    }
    &:active{
      outline: 0px;
      box-shadow: none;
    }
    &:focus{
      outline: 0px;
      box-shadow: 2px 2px 5px 3px rgba(0,0,0,0.2);
      -webkit-box-shadow: 2px 2px 5px 3px rgba(0,0,0,0.2);
      -moz-box-shadow: 2px 2px 5px 3px rgba(0,0,0,0.2);
    }
    
  }
}
    }
}

.rdt_TableHeadRow{
  padding-bottom: 5px;
}

.class_sub_heading{
  top: 15px;
  right: 80px;
  font-family: sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.rdt_TableCol{
  height: 100%;
  >div{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    div> span{
      opacity: 1;
    }
  }
}

.data-table-extensions-action{
  .download{
  &::after{
display: none !important;
  }
  }
}

.jrjCcS{
height: 100% !important;
max-height: 100% !important;
}
.epZUJV{
  svg{
    display: none !important;
  }
}